<template>
    <div>
        <div class="info">
            <div class="info-img" :style="{backgroundImage: 'url(' + require('../../assets/img/' + infoPic) + ')'}"></div>
            <ul>
                <li>SIM卡号：{{arrs.msisdn}}</li>
                <li>ICCID：{{arrs.iccid}}</li>
                <li>套餐名称：{{arrs.packName}}</li>
            </ul>
        </div>
        <div class="detail">
            <span class="detail-title"><i class="iconfont icontaocan"></i>套餐详情</span>
            <ul class="detail-allinfo">
                <li class="detail-allinfo-box">
                    <div class="detail-allinfo-box-left">
                        <van-circle
                            v-model="arrs.rates"
                            :speed="100"
                            layer-color="#ddd"
                            :stroke-width="60"
                            :text="arrs.text"
                        />
                    </div>
                    <ul class="detail-allinfo-box-right">
                        <li>
                            <p>套餐总流量</p>
                            <p>{{arrs.periodCanUsage}}M</p>
                        </li>
                        <li class="info-with-button">
                          <div>
                            <span>套餐已使用流量:{{arrs.usageAmount}}M</span>
                          </div>
                          <div>
                            <button @click="$router.push('/refueing/charge')">加油包</button>
                          </div>
                        </li>
                        <li class="info-with-button">
                          <div>
                            <span>卡片到期时间:{{arrs.cardEndTime}}</span>
                          </div>
                          <div>
                            <button @click="$router.push('/cycle')">续周期</button>
                          </div>
                        </li>
                    </ul>
                </li>
                <li class="detail-allinfo-list">卡片状态：<van-tag size="medium" plain :type="cardStatus.type">{{cardStatus.label}}</van-tag></li>
                <li class="detail-allinfo-list">可使用周期：{{arrs.period}}</li>
                <li class="detail-allinfo-list">周期开始时间：{{arrs.periodStartTime}}</li>
                <li class="detail-allinfo-list">周期结束时间：{{arrs.periodEndTime}}</li>
                <li class="detail-allinfo-list">联系电话：{{arrs.serviceTel}}</li>
            </ul>
        </div>
        <div class="action">
            <ul>
                <li><button @click="$dialog.alert({message: '升级套餐仅限PC端',confirmButtonColor:'#ee0a24'}).then(()=>{})">升级套餐</button></li>
                <li><button @click="$router.push({name:'smsCharge'})">短信充值</button></li>
            </ul>
            <span class="iconfont icongantanhao"> 请及时续周期、充加油包，以免造成卡片停机不能使用。</span>
        </div>
    </div>
</template>

<script>
import Url from '@/common/Url.js';
export default {
    data() {
        return {
            infoPic: 'cmcc.png',
            arrs:{},
            cardStatus:{
                type:'success',
                label:'正常'
            }
        }
    },
    methods: {
        async getInfo() {
            if(!!Url.getQuery("msisdn") && !!Url.getQuery("sign") && !!Url.getQuery("uuid")){
                await this.$api.loginDefault({sign:Url.getQuery("sign"), uuid:Url.getQuery("uuid"),msisdn:Url.getQuery("msisdn")}).then(res => {
                    if(res.data.code == 0){
                        localStorage.setItem("sign",res.data.data.sign);
                        localStorage.setItem("uuid",res.data.data.uuid);
                        localStorage.setItem("msisdn",res.data.data.msisdn);
                    }
                })
            }
            await this.$api.getCardInfo({cardNumber:localStorage.msisdn}).then(res => {
                if(res.data.code == 0){
                    this.arrs = Object.assign({},res.data.data)
                    localStorage.message = JSON.stringify(this.arrs)
                    this.arrs.rates = this.arrs.usageAmount / this.arrs.periodCanUsage * 100
                    this.arrs.text = (this.arrs.usageAmount / this.arrs.periodCanUsage * 100).toFixed(2) + '%'
                    this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
                }
            })
        }
    },
    created() {
         this.$store.commit('setPageTitle', "套餐详情")
        this.getInfo()
        // let msisdn = !!Url.getQuery("msisdn") ? Url.getQuery("msisdn") : localStorage.msisdn
        // let sign = !!Url.getQuery("sign") ? Url.getQuery("sign") : null
        // if(!!sign) localStorage.setItem("sign", Url.getQuery("sign"));
    }
}
</script>

<style lang="scss" scoped>
$actionHeight: 200px; // 底部功能菜单的高度

.info{
    width: 750px;
    height: 255px;
    background-color: #209df2;
    &-img{
        float: left;
        width: 195px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 134px;
    }
    ul{
        float: left;
        margin: 38px 0 0 18px;
        width: 520px;
        li{
            color: #fff;
            font-size: 26px;
            text-align: left;
            height: 61.5px;
            line-height: 61.5px;
        }
    }
}

.detail{
    box-sizing: border-box;
    padding: 0 28px 30px+$actionHeight;
    &-title{
        display: block;
        margin: 50px 0 28px 0;
        font-size: 35px;
        text-align: left;
        i{
            color: #197ded;
            font-size: 35px;
            margin-right: 10px;
        }
    }
    &-allinfo{
        width: 700px;
        margin: 0 auto;
        border: 1Px solid #e5e5e7; // postcss-pxtorem插件不会自动将 Px 和 PX 转换成rem。
        font-size: 24px;
        &-box{
            height:300px;
            &-left{
                float: left;
                width: 43.5%;
                height: 100%;
                border-right: 1Px solid #e5e5e7;
                box-sizing: border-box;
                padding-top:50px; 
            }
            &-right{
                float: left;
                height: 100%;
                width: 56.5%;
                display: flex;
                flex-flow: column;
                li{
                    flex: 1;
                    p{
                        margin: 0;
                        line-height: 50px;
                    }
                }
                li:nth-child(2){
                    border-top: 1Px solid #e5e5e7;
                    border-bottom: 1Px solid #e5e5e7;
                }
            }
        }
        &-list{
            height: 73px;
            line-height: 73px;
            border-top: 1Px solid #e5e5e7;
        }
    }
}

.action{
    position: fixed;
    bottom: 0;
    height: $actionHeight;
    width: 750px;
    background-color: #f7f4f8;
    ul{
        margin: 40px auto 20px;
        width: 690px;
        height: 80px;
        display: flex;
        li{
            flex: 1;
            button{
                border: none;
                border-radius: 10px;
                background-color: #209df2;
                color: #fff;
                font-size: 32px;
                display: block;
                margin: 0 auto;
                width: 80%;
                height: 75px;
                transition: all 0.15s;
            }
            button:hover{
                background-color: #1a7fef;
            }
        }
    }
    span{
        display: block;
        font-size: 22px;
        color: #898989;
    }
}

.info-with-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-with-button button{
  color: #ffffff;
  border: none;
  border-radius: .1rem;
  background: #209df2;
  margin-top: .15rem;
}
</style>